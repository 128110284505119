import { useRouter } from "next/router";
import { useRedirectLegacyHashRoute } from "utils/navigation";

export const CatchAll = (): JSX.Element | null => {
  const router = useRouter();
  const { hashUrl, shouldRedirect } = useRedirectLegacyHashRoute();
  if (shouldRedirect) {
    router.replace(hashUrl);
    return null;
  }

  // TODO: We can implement a proper 404 page here
  router.push({
    pathname: "home",
    query: router.query,
  });
  return null;
};
