import type { NextPage } from "next";
import { CatchAll } from "common/layout/CatchAll/CatchAll";

/**
 * the entry point is used to handle legacy hash urls for backwards compatibility. if a users visits a legacy url, this
 * redirects them to the matching regular route. <br />
 */
const NextEntryPoint: NextPage = CatchAll;

export default NextEntryPoint;
